<template>
  <div>
    <a-modal
        :width="800"
        v-model="show"
        :title="shamTitle"
        ok-text="确认"
        cancel-text="取消"
        @cancel="resetForms"
        @ok="hideModal"
    >
      <a-form-model
          ref="ruleForm"
          :model="params"
          :rules="rules"
          v-bind="layout"
      >
        <a-form-model-item label="分组名称">
          <a-select
              allowClear
              showSearch
              optionFilterProp='children'
              v-model="params.boxShamId"
              placeholder="搜索选择分组名称"
              @search='searchData'
          >
            <a-select-option
                v-for="items of shamNameList"
                :key="items.id"
                :value="items.id"
            >{{ items.shamName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="评级公司">
          <a-select
              allowClear
              v-model="params.boxCompanyType"
              style="width: 250px;"
              placeholder='评级公司'
          >
            <a-select-option
                v-for="item in ratingCompanyData"
                :key="item.id"
                :value='item.id'
            >{{ item.value }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="单个编号">
          <a-input
              style="width: 250px;"
              v-model="params.boxCode"
              placeholder="单个编号"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils";
import {ratingCompanyData} from "@/views/cmsPage/productManage/_data";
export default {
  props: ["detail"],
  data() {
    return {
      repeatSubmit: false,
      untils: untils,
      ratingCompanyData: ratingCompanyData.slice(1),
      show: false,
      boxShamList: [],
      boxShamData: [],
      shamNameList: [],
      params: {
        id: undefined,
        // 假币类型
        shamType: undefined,
        // 假币名称ID
        boxShamId: undefined,
        // 号段
        boxCode: undefined,
        // 评级公司
        boxCompanyType: undefined,
      },
      previewVisible: false,
      previewImage: "",
      coverList: [],
      fileList: [],
      imgKey: "",
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      rules: {
        tagName: [
          { required: true, message: "名称不能为空", trigger: "change" },
        ],
      },
      shamTitle: '新增假币盒子管理',
      shamId: '',
      isLoading: false
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  mounted() {
    addEventListener("paste", (params) => {
      this.untils.pasteImg(params, 1, (a, b, s) => {
        this.coverList.push({
          uid: this.coverList.length + 1,
          name: "image",
          status: "done",
          url: b,
        });
      });
    });
  },
  methods: {
    async showPopup(data) {
      this.resetForms()
      this.$loading.show()
      if (data) {
        this.shamId = data.id
        this.shamTitle = '编辑假币盒子管理'
        await this.searchData(data.shamName)
        await this.setDetail(data)
      } else {
        await this.searchData()
      }
      this.$loading.hide()
      this.show = true
    },
    setDetail(data) {
      this.params = Object.assign(this.params, data)
    },
    hideModal() {
      if (this.isLoading) return this.$message.warn('请勿重复提交')
      if (!this.params.boxShamId) return this.$message.warn('假币名称为不能为空')
      if (!this.params.boxCompanyType) return this.$message.warn('评级公司不能为空')
      if (!this.params.boxCode) return this.$message.warn('编号不能为空')
      this.submit(this.params)
    },
    async submit(forms) {
      delete forms.shamType
      const sendObj = {
        ...forms
      };
      this.$loading.show()
      this.isLoading = true
      const res = await this.axios.post(
          "/dq_admin/boxBase/addOrUpdateBox",
          sendObj
      )
      this.$loading.hide()
      this.isLoading = false
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.resetForms()
      this.$emit("success");
    },
    resetForms() {
      this.params = {
        id: undefined,
        // 假币类型
        shamType: undefined,
        // 假币名称ID
        boxShamId: undefined,
        // 号段
        boxCode: undefined,
        // 评级公司
        boxCompanyType: undefined,
        // 描述
        remark: undefined
      }
      this.show = false
    },
    /** 获取假币名称 */
    async searchData(inner) {
      await this.axios('/dq_admin/boxShamType/list',{
        params: {
          shamName: inner ? `${inner}` : '',
          pageSize: 50
        }
      }).then(res=>{
        if(res.status == '200'){
          const {records} = res.data
          this.shamNameList = records
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
</style>
