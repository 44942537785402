<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
      <div slot-scope="row" slot="boxCompanyTypeSlot" >
        <div v-if="row.boxCompanyType">{{ ratingCompanyData.find(el => el.id === row.boxCompanyType).value }}</div>
      </div>
      <div slot-scope="row" slot="itemImgSlot">
        <div class="itemIcon">
          <img v-if="row.imageList" class="icon-box" :src="row.imageList[0]" alt="" @click="handlePreviewImg(row.imageList)">
          <div v-else class="img-null">暂无图片</div>
        </div>
        <div v-if="!row.imageList">
          <a-button
              size="small"
              type="primary"
              class="setup-btn mt-10"
              @click="handleEvent(row, 'resetGetData')"
          >重新获取官网图</a-button>
        </div>
      </div>
      <div slot-scope="row" slot="itemShamTypeSlot">
        <div
            :class="shamTypeListClass[row.boxShamType.shamType]"
        >{{ shamTypeText[row.boxShamType.shamType] }}</div>
        <div>{{ row.boxShamType.shamName }}</div>
      </div>
      <div slot-scope="row" slot="action">
        <div>
          <a-button
              size="small"
              type="primary"
              class="setup-btn mt-10"
              @click="handleEvent(row, 'editor')"
          >编辑</a-button>
        </div>
        <div class="mt-10">
          <a-popconfirm
              title="确定删除吗？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleEvent(row, 'delete')"
          >
            <a-button
                size="small"
                type="danger"
                class="setup-btn mt-10"
            >删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
    <ShamEditor ref="shamEditorEl" @success="handleEditor"/>
  </div>
</template>
<script>

import {shamBoxTableColumns} from "@/views/cmsPage/productManage/productTagList/_data";
import ShamEditor from "@/views/cmsPage/productManage/boxShamAboutList/boxShamList/_components/ShamEditor";
import {ratingCompanyData} from "@/views/cmsPage/productManage/_data";

export default {
  props: ['pagination', 'list'],
  components: {ShamEditor},
  data() {
    return {
      shamTypeListClass: {
        1: 'color-red',
        10: 'color-red-1',
        20: 'color-red-2'
      },
      shamTypeText: {
        1: '批量假',
        10: '同模假',
        20: '真盒假币'
      },
      tableList: shamBoxTableColumns,
      ratingCompanyData,
    }
  },
  methods: {
    /** 操作 */
    handleEvent(row, type) {
      if (type === 'resetGetData') {
        return this.$message.warn('TODO')
        this.handleResetGetData(row)
      }
      if (type === 'delete') {
        this.handleDelete(row)
      }
      if (type === 'editor') {
        const data = {
          id: row.id,
          codeType: row.codeType,
          boxCode: row.boxCode,
          boxCompanyType: row.boxCompanyType,
          boxShamId: row.boxShamType.id,
          remark: row.remark,
          shamName: row.boxShamType.shamName,
          shamType: row.boxShamType.shamType
        }
        this.$refs.shamEditorEl.showPopup(data)
      }
    },
    /** 重新获取官网图 */
    async handleResetGetData(row) {
      const res = await this.axios("/dq_admin/productTag/delById", {
        params: {
          id: row.id
        },
      });
      if (res.status !== '200') return
      this.handleEditor()
    },
    async handleDelete(row) {
      return this.$message.warn('TODO')
      const res = await this.axios("/dq_admin/productTag/delById", {
        params: {
          id: row.id
        },
      });
      if (res.status !== '200') return
      this.handleEditor()
    },
    /** 修改好了 */
    handleEditor() {
      this.$emit("success");
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    handlePreviewImg(images) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-box {
  width: 100px;
  height: 100px;
}
.img-null {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
}
.itemIcon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-red {
  color: red;
}
.color-red-2 {
  color: #ff6374;
}
.color-red-1 {
  color: #bb4b4a;
}
.color-green {
  color: #008a00;
}
.color-blue {
  color: #2d8cf0;
}
.mt-10 {
  margin-top: 10px;
}
</style>