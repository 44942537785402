var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            staticStyle: { width: "220px" },
            attrs: { allowClear: "", size: "small", placeholder: "盒子编码" },
            model: {
              value: _vm.params.boxCode,
              callback: function($$v) {
                _vm.$set(_vm.params, "boxCode", $$v)
              },
              expression: "params.boxCode"
            }
          }),
          _c(
            "a-select",
            {
              staticStyle: { "margin-left": "5px", width: "250px" },
              attrs: {
                allowClear: "",
                showSearch: "",
                placeholder: "假币类型",
                size: "small"
              },
              on: { change: _vm.handleChangeShamType },
              model: {
                value: _vm.params.shamType,
                callback: function($$v) {
                  _vm.$set(_vm.params, "shamType", $$v)
                },
                expression: "params.shamType"
              }
            },
            _vm._l(_vm.boxShamData, function(item) {
              return _c(
                "a-select-option",
                { key: item.shamType, attrs: { value: item.shamType } },
                [_vm._v(_vm._s(item.shamTypeName))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { "margin-left": "5px", width: "250px" },
              attrs: {
                allowClear: "",
                showSearch: "",
                optionFilterProp: "children",
                size: "small",
                placeholder: _vm.params.shamType ? "名称" : "请先选择假币类型"
              },
              model: {
                value: _vm.params.shamTypeId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "shamTypeId", $$v)
                },
                expression: "params.shamTypeId"
              }
            },
            _vm._l(_vm.boxShamList, function(items) {
              return _c(
                "a-select-option",
                { key: items.id, attrs: { value: items.id } },
                [_vm._v(_vm._s(items.shamName))]
              )
            }),
            1
          ),
          _c(
            "a-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.search }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "a-button",
            { attrs: { size: "small" }, on: { click: _vm.handleAdd } },
            [_vm._v("新增")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("TableList", {
            attrs: { list: _vm.data, pagination: _vm.pagination },
            on: { changePage: _vm.handleChangePage, success: _vm.getList }
          })
        ],
        1
      ),
      _c("ShamEditor", { ref: "shamEditorEl", on: { success: _vm.getList } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }