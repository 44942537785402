<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.boxCode"
          size='small'
          style="width: 220px"
          placeholder='盒子编码'
      ></a-input>
      <a-select
          allowClear
          showSearch
          style="margin-left: 5px;width: 250px;"
          v-model="params.shamType"
          placeholder="假币类型"
          size="small"
          @change="handleChangeShamType"
      >
        <a-select-option
            v-for="item of boxShamData"
            :key="item.shamType"
            :value="item.shamType"
        >{{ item.shamTypeName }}</a-select-option>
      </a-select>
      <a-select
          allowClear
          showSearch
          optionFilterProp='children'
          style="margin-left: 5px;width: 250px;"
          size="small"
          v-model="params.shamTypeId"
          :placeholder="params.shamType ? '名称' : '请先选择假币类型'"
      >
        <a-select-option
            v-for="items of boxShamList"
            :key="items.id"
            :value="items.id"
        >{{ items.shamName }}</a-select-option>
      </a-select>
      <a-button
          size="small"
          type="primary"
          @click="search"
      >搜索</a-button>
      <a-button
          size="small"
          @click="handleAdd"
      >新增</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
    <ShamEditor
        ref='shamEditorEl'
        @success="getList"
    />
  </div>
</template>
<script>
import ShamEditor from "@/views/cmsPage/productManage/boxShamAboutList/boxShamList/_components/ShamEditor";
import TableList from "@/views/cmsPage/productManage/boxShamAboutList/boxShamList/_components/TableList";
import {ratingCompanyData} from "@/views/cmsPage/productManage/_data";

export default {
  components: {
    TableList,
    ShamEditor
  },
  data() {
    return {
      ratingCompanyData: ratingCompanyData,
      data: [],
      boxShamData: [],
      boxShamList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        shamTypeId: undefined,
        boxCode: undefined,
        shamType: undefined,
      },
    };
  },
  async mounted() {
    await this.getShamTypeListData()
    await this.getList()
  },
  methods: {
    /** 增加 */
    handleAdd() {
      this.$refs.shamEditorEl.showPopup()
    },
    editSuccess() {
      this.getList()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.params.pageNum = 1;
      this.pagination.current = 1;
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/boxBase/list", {
        params: {
          ...this.params
        },
      });
      this.$loading.hide()
      if (res.status != 200) return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
    async getShamTypeListData(){
      await this.axios(
          '/dq_admin/boxShamType/shamGroupList'
      ).then(res=>{
        this.boxShamData = res.data
      })
    },
    /** 切换假币类型 */
    handleChangeShamType(e) {
      delete this.params.shamTypeId
      if (e) {
        this.boxShamList = this.boxShamData.find(el => el.shamType === e).children
      } else {
        this.boxShamList = []
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
